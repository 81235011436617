<template>
    <div class="bg-gray-50">
    <div class="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
      <div class="max-w-3xl mx-auto divide-y-2 divide-gray-200">
        <h2 class="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl">
          Profile
        </h2>
      </div>

      <div v-if="user" class="max-w-3xl mx-auto mt-10">
        <div class="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
            <div class="flex-shrink-0">
                <!-- <img  class="h-10 w-10 rounded-full" :src="user.photoURL" alt="Profile Avatar" /> -->
                <UserIcon class="h-6 w-6"></UserIcon>
            </div>
            <div class="flex-1 min-w-0">
                <div class="focus:outline-none">
                <span class="absolute inset-0" aria-hidden="true" />
                <p class="text-sm font-medium text-gray-900">
                    {{ user.displayName }}
                </p>
                <p class="text-sm text-gray-500 truncate">
                    {{ user.email }}
                </p>
                <p v-show="user.providerData[0]" class="text-sm text-gray-500 truncate">
                    {{ user.providerData[0] ? user.providerData[0].providerId : '' }}
                </p>
            </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from  "../../firebase"
import {
    UserIcon
} from '@heroicons/vue/outline'

export default {
    components: {
        UserIcon
    },
    data() {
        return {
            user: null
        }
    },
    created() {
        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                this.user = user
            }
        })
    }
}
</script>
